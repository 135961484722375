import axiosInstance from "./axios";
import { getUserIdFromToken } from "@/utils/jwt";

export interface TransactionEvaluation {
  amount: number;
  date_completed: string | null;
  date_created: string;
  date_updated: string | null;
  evaluation_id: string;
  fraud_score: number | null;
  fraud_score_reason: string | null;
  receiver_address: string;
  risk_level: string | null;
  sender_address: string;
  status: string;
  timestamp: string;
  transaction_hash: string;
}

export interface RiskFactor {
  factor: string;
  score: number;
  weight: number;
}

export interface Recommendation {
  id: string;
  content: string;
  urgency: string;
  completed: boolean;
  date_created: string;
}

export interface FraudEvaluation {
  user_id: string;
  request_id: string;
  evaluation_id: string;
  target: string;
  target_type: string | null;
  active: boolean | null;
  blockchain_type: string | null;
  balance: number | null;
  status: string;
  fraud_score: number | null;
  evaluation_comment: string | null;
  risk_level: string | null;
  date_created: string;
  date_updated: string | null;
  date_completed: string | null;
  date_last_score_update: string | null;
  transactions_evaluated: TransactionEvaluation[];
  risk_breakdown: RiskFactor[];
  recommendations: Recommendation[];
}

export interface FraudEvaluationsResponse {
  items: FraudEvaluation[];
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
}

export interface InitiateFraudEvalRequest {
  active?: boolean | null;
  balance?: number | null;
  blockchain_type?: string | null;
  target: string;
  target_type?: string | null;
}

export interface FraudEvaluationTarget {
  target: string;
  date_updated: string;
}

interface FraudEvaluationTargetsResponse {
  items: FraudEvaluationTarget[];
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
}

export const fraudEvaluationService = {
  async getFraudEvaluations(): Promise<FraudEvaluationsResponse> {
    const userId = getUserIdFromToken();
    if (!userId) throw new Error("User not authenticated");

    try {
      const response = await axiosInstance.get(
        `/api/fes/v1/users/${userId}/fraud-evaluations`,  // Changed from /a/fes/api/v1/...
        {
          headers: {
            'Content-Type': 'application/json'
          },
          params: {
            page_size: 100
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching fraud evaluations:", error);
      throw error;
    }
  },

  async initiateFraudEvaluation(
    walletAddress: string
  ): Promise<FraudEvaluation> {
    const userId = getUserIdFromToken();
    if (!userId) throw new Error("User not authenticated");

    try {
      const response = await axiosInstance.post(
        `/api/fes/v1/users/${userId}/fraud-evaluations`,  // Changed from /a/fes/api/v1/...
        {
          target: walletAddress,
          // target_type: "wallet_address",
          active: true,
          balance: 0,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error initiating fraud evaluation:", error);
      throw error;
    }
  },

  async getFraudEvaluationById(requestId: string): Promise<FraudEvaluation> {
    const userId = getUserIdFromToken();
    if (!userId) throw new Error("User not authenticated");

    try {
      const response = await axiosInstance.get(
        `/api/fes/v1/users/${userId}/fraud-evaluations/${requestId}`,  // Changed from /a/fes/api/v1/...
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching fraud evaluation details:", error);
      throw error;
    }
  },

  async getFraudEvaluationTargets(): Promise<FraudEvaluationTargetsResponse> {
    const userId = getUserIdFromToken();
    if (!userId) throw new Error("User not authenticated");

    try {
      const response = await axiosInstance.get(
        `/api/fes/v1/users/${userId}/fraud-evaluations/targets`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching fraud evaluation targets:", error);
      throw error;
    }
  },

  async updateRecommendationStatus(
    evaluationId: string,
    recommendationId: string,
    completed: boolean
  ): Promise<void> {
    const userId = getUserIdFromToken();
    if (!userId) throw new Error("User not authenticated");

    try {
      await axiosInstance.patch(
        `/api/fes/v1/users/${userId}/fraud-evaluations/${evaluationId}/recommendations/${recommendationId}/status`,
        {
          completed
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error("Error updating recommendation status:", error);
      throw error;
    }
  },
};
