"use client";

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type ToastType = 'default' | 'success' | 'error' | 'warning' | 'info';

interface Toast {
  id: number;
  title: string;
  message: string;
  type: ToastType;
  duration?: number;
}

interface ToastContextType {
  addToast: (title: string, message: string, type?: ToastType, duration?: number) => void;
  removeToast: (id: number) => void;
}

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastProps extends Toast {
  onClose: () => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

const ToastIcons = {
  success: (
    <svg className="w-5 h-5 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
    </svg>
  ),
  error: (
    <svg className="w-5 h-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  ),
  warning: (
    <svg className="w-5 h-5 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
  ),
  info: (
    <svg className="w-5 h-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  ),
  default: (
    <svg className="w-5 h-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  )
};

const Toast: React.FC<ToastProps> = ({ title, message, type, duration = 5000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getBorderColor = () => {
    switch (type) {
      case 'success': return 'border-r-4 border-green-500';
      case 'error': return 'border-r-4 border-red-500';
      case 'warning': return 'border-r-4 border-yellow-500';
      case 'info': return 'border-r-4 border-blue-500';
      default: return 'border-r-4 border-blue-500';
    }
  };

  return (
    <div
      className={`flex items-start w-auto min-w-[200px] max-w-[300px] bg-white rounded shadow-sm ${getBorderColor()}`}
      role="alert"
    >
      <div className="flex items-start space-x-3 p-3">
        <div className="flex-shrink-0 pt-0.5">
          {ToastIcons[type]}
        </div>
        <div className="flex-1">
          {title && (
            <p className="text-sm font-medium text-gray-900">
              {title}
            </p>
          )}
          {message && (
            <p className="mt-1 text-sm text-gray-500">
              {message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export function ToastProvider({ children }: ToastProviderProps): React.ReactElement {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (title: string, message: string = '', type: ToastType = 'default', duration = 3000): void => {
    const id = Date.now();
    setToasts(prev => [...prev, { id, title, message, type, duration }]);
  };

  const removeToast = (id: number): void => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <div 
        className="fixed top-4 right-4 z-50 flex flex-col items-end gap-2"
        aria-live="polite"
      >
        {toasts.map(toast => (
          <div
            key={toast.id}
            className="toast-enter"
          >
            <Toast {...toast} onClose={() => removeToast(toast.id)} />
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
}

export function useToast(): ToastContextType {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}