/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fraudEvaluationService, FraudEvaluation, FraudEvaluationTarget } from '@/services/api/fraudEvaluationService';
import { PayloadAction } from '@reduxjs/toolkit';

interface FraudEvaluationState {
  evaluations: FraudEvaluation[];
  loading: boolean;
  error: string | null;
  selectedEvaluation: FraudEvaluation | null;
  sortOption: 'newest' | 'oldest';
  searchTerm: string;
  evaluationTargets: FraudEvaluationTarget[];
  recommendationUpdateLoading: boolean;
  initiationLoading: boolean;
}

const initialState: FraudEvaluationState = {
  evaluations: [],
  loading: false,
  error: null,
  selectedEvaluation: null,
  sortOption: 'newest',
  searchTerm: '',
  evaluationTargets: [],
  recommendationUpdateLoading: false,
  initiationLoading: false,
};

export const fetchFraudEvaluations = createAsyncThunk(
  'fraudEvaluation/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await fraudEvaluationService.getFraudEvaluations();
    } catch (error) {
      return rejectWithValue('Failed to fetch fraud evaluations');
    }
  }
);

export const initiateFraudEvaluation = createAsyncThunk(
  'fraudEvaluation/initiate',
  async (walletAddress: string, { rejectWithValue }) => {
    console.log("wallet Address in slice: ", walletAddress)
    try {
      return await fraudEvaluationService.initiateFraudEvaluation(walletAddress);
    } catch (error) {
      return rejectWithValue('Failed to initiate fraud evaluation');
    }
  }
);

export const fetchFraudEvaluationById = createAsyncThunk(
  'fraudEvaluation/fetchById',
  async (requestId: string, { rejectWithValue, getState }) => {
    // Add a new parameter to track if this is called from recommendation update
    const fromRecommendationUpdate = requestId.startsWith('recommendation_update:');
    
    // Extract the actual ID if it's from recommendation update
    const actualRequestId = fromRecommendationUpdate 
      ? requestId.replace('recommendation_update:', '') 
      : requestId;
    
    try {
      return await fraudEvaluationService.getFraudEvaluationById(actualRequestId);
    } catch (error) {
      return rejectWithValue('Failed to fetch fraud evaluation details');
    }
  }
);

export const fetchFraudEvaluationTargets = createAsyncThunk(
  'fraudEvaluation/fetchTargets',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fraudEvaluationService.getFraudEvaluationTargets();
      localStorage.setItem('fraudEvaluationTargets', JSON.stringify(response.items));
      return response;
    } catch (error) {
      return rejectWithValue('Failed to fetch fraud evaluation targets');
    }
  }
);

export const updateRecommendationStatus = createAsyncThunk(
  'fraudEvaluation/updateRecommendationStatus',
  async ({ 
    evaluationId, 
    recommendationId, 
    completed 
  }: { 
    evaluationId: string; 
    recommendationId: string; 
    completed: boolean 
  }, { dispatch, rejectWithValue }) => {
    try {
      await fraudEvaluationService.updateRecommendationStatus(
        evaluationId,
        recommendationId,
        completed
      );
      
      // Fetch the updated evaluation data with the special prefix
      await dispatch(fetchFraudEvaluationById(`recommendation_update:${evaluationId}`));
      return { recommendationId, completed };
    } catch (error) {
      return rejectWithValue('Failed to update recommendation status');
    }
  }
);

const fraudEvaluationSlice = createSlice({
  name: 'fraudEvaluation',
  initialState,
  reducers: {
    clearSelectedEvaluation: (state) => {
      state.selectedEvaluation = null;
    },
    setSortOption: (state, action: PayloadAction<'newest' | 'oldest'>) => {
      state.sortOption = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    clearFilters: (state) => {
      state.searchTerm = '';
      state.sortOption = 'newest';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFraudEvaluations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFraudEvaluations.fulfilled, (state, action) => {
        state.loading = false;
        state.evaluations = action.payload.items;
      })
      .addCase(fetchFraudEvaluations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(initiateFraudEvaluation.pending, (state) => {
        state.initiationLoading = true;
        state.error = null;
      })
      .addCase(initiateFraudEvaluation.fulfilled, (state, action) => {
        state.initiationLoading = false;
        state.evaluations.unshift(action.payload);
        state.selectedEvaluation = action.payload;
      })
      .addCase(initiateFraudEvaluation.rejected, (state, action) => {
        state.initiationLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchFraudEvaluationById.pending, (state, action) => {
        // Check if the request is from a recommendation update
        const isFromRecommendationUpdate = action.meta.arg.toString().startsWith('recommendation_update:');
        
        // Only set loading to true if it's not from a recommendation update
        if (!isFromRecommendationUpdate) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(fetchFraudEvaluationById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedEvaluation = action.payload;
      })
      .addCase(fetchFraudEvaluationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchFraudEvaluationTargets.fulfilled, (state, action) => {
        state.evaluationTargets = action.payload.items;
      })
      .addCase(fetchFraudEvaluationTargets.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(updateRecommendationStatus.pending, (state) => {
        state.recommendationUpdateLoading = true;
        state.error = null;
      })
      .addCase(updateRecommendationStatus.fulfilled, (state) => {
        state.recommendationUpdateLoading = false;
      })
      .addCase(updateRecommendationStatus.rejected, (state, action) => {
        state.recommendationUpdateLoading = false;
        state.error = action.payload as string;
      });
  }
});

// Selector for filtered and sorted evaluations
export const selectFilteredAndSortedEvaluations = (state: { fraudEvaluation: FraudEvaluationState }) => {
  const { evaluations, searchTerm, sortOption } = state?.fraudEvaluation;
  
  let filteredEvaluations = evaluations;
  
  // Apply search filter
  if (searchTerm) {
    filteredEvaluations = evaluations.filter(evaluation =>
      evaluation?.target?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }

  // Apply sorting
  return [...filteredEvaluations].sort((a, b) => {
    const dateA = new Date(a.date_created);
    const dateB = new Date(b.date_created);
    return sortOption === 'newest'
      ? dateB.getTime() - dateA.getTime()
      : dateA.getTime() - dateB.getTime();
  });
};

export const { 
  clearSelectedEvaluation,
  setSortOption,
  setSearchTerm,
  clearFilters
} = fraudEvaluationSlice.actions;

export default fraudEvaluationSlice.reducer; 