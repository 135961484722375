import { secureStorage } from "@/utils/encryption";
import axios, { AxiosInstance } from "axios";

// API Keys
const API_KEYS = {
  FES: "52c6b0ca-9c2f-4571-a431-6ed04eecad39",
  USS: "cb16a3a4-6c11-4926-ba4f-228f1e0cf4e4",
};

// Create axios instance with default config
const axiosInstance: AxiosInstance = axios.create({
  baseURL: "",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  // Enable credentials for cross-origin requests if needed
  withCredentials: false,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = secureStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add API key based on URL
    const url = config.url?.toLowerCase() || "";

    if (url.includes("fes")) {
      config.headers["x-api-key"] = API_KEYS.FES;
    } else if (url.includes("uss")) {
      config.headers["x-api-key"] = API_KEYS.USS;
    }

    // For preflight OPTIONS requests
    if (config.method === "options") {
      config.headers["Access-Control-Request-Headers"] =
        "authorization,content-type,x-api-key";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Check if auth-related items exist before removing
      const hasAuthItems =
        secureStorage.getItem("auth_token") ||
        secureStorage.getItem("nonce") ||
        secureStorage.getItem("walletConnection") ||
        secureStorage.getItem("wallet_address");
      window.location.href = "/";

      if (hasAuthItems) {
        // Only clear storage and redirect if auth items existed
        secureStorage.removeItem("auth_token");
        secureStorage.removeItem("nonce");
        secureStorage.removeItem("walletConnection");
        secureStorage.removeItem("wallet_address");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
