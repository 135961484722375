import { jwtDecode } from 'jwt-decode';
import { secureStorage } from './encryption';

interface JWTPayload {
  sub: string;  // user_id
  exp: number;
  iat: number;
}

export const getUserIdFromToken = (): string | null => {
  try {
    const token = secureStorage.getItem('auth_token');
    if (!token) return null;
    
    const decoded = jwtDecode<JWTPayload>(typeof token === 'string' ? token : JSON.stringify(token));
    return decoded.sub;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};

export const isTokenValid = (): boolean => {
  try {
    const token = secureStorage.getItem('auth_token');
    if (!token) return false;
    
    const decoded = jwtDecode<JWTPayload>(typeof token === 'string' ? token : JSON.stringify(token));
    const currentTime = Math.floor(Date.now() / 1000);
    
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Error checking token validity:', error);
    return false;
  }
};

export const checkAuthAndRedirect = (): boolean => {
  if (!isTokenValid()) {
    secureStorage.removeItem('auth_token');
    window.location.href = '/login';
    return false;
  }
  return true;
}; 