import { AES, enc } from 'crypto-js';

// Get encryption key from environment variable
const ENCRYPTION_KEY = process.env.NEXT_PUBLIC_ENCRYPTION_KEY || 'fallback-key-123';

export const encrypt = <T>(data: T): string => {
  try {
    const jsonString = JSON.stringify(data);
    return AES.encrypt(jsonString, ENCRYPTION_KEY).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return '';
  }
};

export const decrypt = <T>(encryptedData: string): T | null => {
  try {
    const bytes = AES?.decrypt(encryptedData, ENCRYPTION_KEY);
    const decryptedString = bytes.toString(enc.Utf8);
    return JSON.parse(decryptedString);
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};

// Secure localStorage wrapper
export const secureStorage = {
  setItem<T>(key: string, value: T) {
    const encrypted = encrypt(value);
    localStorage.setItem(key, encrypted);
  },

  getItem(key: string) {
    const encrypted = localStorage.getItem(key);
    if (!encrypted) return null;
    return decrypt(encrypted);
  },

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}; 